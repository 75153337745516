//General Specific to product list
@import '../../../partials/general';
@import '../../../partials/mobile/general';
@import '../../../partials/mobile/header';
@import '../../../partials/mobile/footer';
@import '../../../widgets/breadcrumbs/general';
@import '../../../widgets/social/general';
@import '../../../partials/general/actions';
@import '../../../partials/general/actions_filter';
@import 'general';

.discount-circle {
  top: -10px;
  right: -5px;
}

.results {
    .results-container{ 
        &.store-product-list {
            padding-bottom: 200px;            
        }
    }
}

.store-product-list{
  .product-list-page:first-of-type {
    margin-top: 0;
  }
  .mobile-col-img {
    width: 125px;
    padding-right: 0;
    float: left;
  }
  .mobile-col-info {
    width: calc(100% - 130px);
    padding-left: 5px;
    float: right;
  }

  .products-list-components-wrapper{
    &.one-product-components-row{
      .one{
        .open-components-triangle{
          left: 130px;
        }
      }
    }
    .products-list-components-row{
      padding-top: 25px;
      &.last{
        padding-bottom: 25px;
      }
    }
  }

  .product-row-wrapper {
    border-bottom: none;
  }

  .products-list-row{
      &.root-products-row{
        border-bottom: none;
        padding-top: 20px;
        padding-bottom: 10px;
      }
      .product-title {
        padding-top: 5px;
        font-size: 18px;
        line-height: 22px;
        text-transform: none;
      }
  }

  .product-info {
    .product-info-img-container {
      height: 180px;
      .product-info-img {
        width: 110px;
        height: 180px;
        img {
          width: 100%;
          max-height: 180px;
        }
      }
    }
    .buy-button .text {
      right: 17px;
    }
    .buy-button .icon {
      left: 0;
    }
    .product-price{
      padding-top: 5px;
    }
    .product-author {
      padding-top: 0;
    }
  }

  .product-list-view-more-container {
    .product-list-view-more-btn {
      width: 300px;
      margin: 0 auto;
    }
  }

}