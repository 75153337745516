@import '../../partials/general';
@import '../../partials/mobile/general';
@import '../../partials/mobile/header';
@import '../../partials/mobile/footer';
@import '../../widgets/breadcrumbs/general';
@import '../../widgets/social/general';
@import '../partials/product-list/mobile';
@import "filters_general";

.search-filters {
    padding: 20px 0;
    .btn-group {
        width: 100%;
    }
    .filters-item {
        width: 100%;
        &.filter {
            width: 100%;
        }
    }
    .actions-dropdown{
        margin-top: 15px;
    }
}

.filters-keywords {
    padding-top: 15px;
}

.filters-modal {
    .modal-dialog {
        .modal-content {
            .modal-header {
                padding: 0px 10px;
            }
        }
    }

    .filters-list{
        .filter-checkbox{
            padding: 5px 10px 5px 10px;
        }
    }

}